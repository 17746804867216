
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from './profileService'

const initialState = {
    profiles: [],
    profile: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder


            .addCase(getProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.profile = action.payload
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getMyProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMyProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.profile = action.payload
            })
            .addCase(getMyProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(updateMyProfile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMyProfile.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.profiles.unshift(action.payload)
            })
            .addCase(updateMyProfile.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })
    }
})

export const getProfile = createAsyncThunk('profile/getProfile', async (userId, thunkAPI) => {
    try {
        return await profileService.getProfile(userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMyProfile = createAsyncThunk('profile/getMyProfile', async (_, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        console.log("getProfile userId: " + userId);
        return await profileService.getMyProfile(userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateMyProfile = createAsyncThunk('profile/updateMyProfile', async (profileData, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await profileService.updateMyProfile(profileData, userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const { reset } = profileSlice.actions
export default profileSlice.reducer
