

import { db } from "../../config/firebase";
import { collection, addDoc, getDoc, query, where, getDocs, doc, deleteDoc, updateDoc, orderBy, arrayUnion, Timestamp } from 'firebase/firestore'


const createEvent = async (eventData, user) => {
    const colRef = await collection(db, 'meetings');
    const docRef = await addDoc(colRef, { ...eventData, uid: user.uid })
    console.log(docRef);
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id: docSnap.id };
}

const getEvents = async () => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        //where("isactivity", "==", true),
        where("explore", "==", true),
        where("businessId", "==", "j6HB1d7Ua41NTFhvH4lw"),
        where('endDate', '>=', new Date()),
        orderBy('endDate'),
        //where('endDate', '>=', new Date())
        //where("startDate", ">=", new Date()),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getEventsPast = async () => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        //where("isactivity", "==", true),
        where("explore", "==", true),
        where("businessId", "==", "j6HB1d7Ua41NTFhvH4lw"),
        where('endDate', '<=', new Date()),
        orderBy('endDate', 'desc'),
        //where('endDate', '>=', new Date())
        //where("startDate", ">=", new Date()),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getSubEvents = async (eventId) => {
    const colRef = await collection(db, 'meetings');
    const q = query(
        colRef,
        where("topmeetingid", "==", eventId),
        where("issubmeeting", "==", true),
        where("isbtob", "==", false),
        orderBy('startDate'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getEvent = async (eventId) => {
    const docRef = await doc(db, 'meetings', eventId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return null;
    }
}

const deleteEvent = async (id) => {
    const docRef = await doc(db, 'meetings', id);
    await deleteDoc(docRef);
    return id
}

const updateEvent = async (eventData, id) => {
    const docRef = await doc(db, 'meetings', id);
    await updateDoc(docRef, eventData)
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id: docSnap.id };
}

const getEventUsers = async (eventId) => {
    const colRef = await collection(db, 'users');
    const q = query(
        colRef,
        where('meetings', 'array-contains-any', [eventId]),
        orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getEventAgendas = async (eventId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', eventId, 'agenda');
    const q = query(
        colRef,
        where('status', "==", true),
        orderBy('startTime', 'asc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const getEventNotes = async (eventId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', eventId, 'notes');
    const q = query(
        colRef,
        where('users', 'array-contains-any', [userId]),
        orderBy('messageDate', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}

const createEventNote = async (noteData, eventId, userId) => {
    const colRef = await collection(db, 'meetings', eventId, 'notes');
    const docRef = await addDoc(colRef, {
        ...noteData,
        messageDate: Timestamp.fromDate(new Date()),
        userId: userId,
        users: [userId],
    })
    const docSnap = await getDoc(docRef);
    console.log("docSnap.id: " + docSnap.id);


    const docRefU = await doc(db, 'meetings', eventId, 'notes', docSnap.id);
    await updateDoc(docRefU, { noteId: docSnap.id })

    return { ...docSnap.data(), noteId: docSnap.id };
}


const joinEvent = async (eventId, userId) => {
    const docRefUser = await doc(db, 'users', userId);

    const docRefMeeting = await doc(db, 'meetings', eventId);


    try {

        await updateDoc(docRefMeeting, {
            users: arrayUnion(userId)
        });

        // Diziye yeni elemanı ekleyin
        await updateDoc(docRefUser, {
            meetings: arrayUnion(eventId)
        });



        console.log('Diziye yeni eleman başarıyla eklendi.');
    } catch (error) {
        console.error('Diziye eleman eklerken hata oluştu:', error);
    }

    return { status: "true" };
}


const getEventAnnouncements = async (eventId) => {
    const userId = JSON.parse(localStorage.getItem('user')).uid;
    const colRef = await collection(db, 'meetings', eventId, 'announcement');
    const q = query(
        colRef,
        where('status', "==", true),
        orderBy('messageDate', 'desc'),
    );
    const querySnapshot = await getDocs(q);
    let dizi = [];
    querySnapshot.forEach((doc) => {
        dizi.push({ ...doc.data(), id: doc.id })
    });
    return dizi;
}




const eventService = {
    createEvent,
    getEvents,
    deleteEvent,
    updateEvent,
    getEvent,
    getSubEvents,
    getEventUsers,
    getEventNotes,
    createEventNote,
    joinEvent,
    getEventsPast,
    getEventAgendas,
    getEventAnnouncements,
}

export default eventService