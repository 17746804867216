import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns';

import Spinner from '../../components/Spinner/Spinner'
import { getEvents, reset } from '../../features/event/eventSlice'



function EventList() {



    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const { events, isLoading, isError, message } = useSelector((state) => state.event);


    useEffect(() => {

        if (!isAuthenticated) {
            navigate('/login')
        }

        if (isError) {
            console.log(message);
        }


        dispatch(getEvents())

        /*
        return () => {
            if (isAuthenticated) {
                dispatch(reset())
            }
        }
        */

    }, [isAuthenticated, navigate, isError, message, dispatch])


    if (isLoading) {
        return <Spinner />
    }







    return (
        <>
            <div className="row">

                {events.length > 0 ? (
                    <>
                        {events.map((event, index) => (
                            <>
                                < div className="col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6" key={index} >
                                    <Link to={"/EventDetailVideo/" + event.meetingId}>
                                        <div className="card project-boxed">
                                            <div className="img-bx">
                                                <img src={event.coverPhoto} alt="" className=" me-3 card-list-img w-100 cover_event_list" width="130" />
                                            </div>
                                            <div className="card-header align-items-start">
                                                <div>
                                                    <h6 className="fs-18 font-w500 mb-3 text-black user-name">{event.title}</h6>
                                                    <div className="text-dark fs-14 text-nowrap"><i className="fa fa-calendar me-3" aria-hidden="true"></i>{format(event.startDate.toDate(), "dd MMM yyyy HH:mm")}</div>
                                                    <div className="text-dark fs-14 text-nowrap"><i className="fa fa-map me-3" aria-hidden="true"></i>Online</div>
                                                    <div className="text-dark fs-14 text-nowrap"><i className="fa fa-users me-3" aria-hidden="true"></i>{event.users?.length} Katılımcı</div>
                                                    <div className="text-dark fs-14 text-nowrap"><i className="fa fa-building me-3" aria-hidden="true"></i>Düzenleyen <strong>Lonca</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        ))}
                    </>
                ) : (
                    <h3>Etkinlik Bulunamadı</h3>
                )}
            </div >
        </>

    )
}

export default EventList