import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const authSlice = createSlice({
    name: 'authSlice',
    //initialState,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        },
        /*
        setUser: (state, action) => {
            state.user = action.payload;
        },
        */
        setUser(state, action) {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
                console.log(action.payload)
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })

            .addCase(loginotp.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loginotp.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload

                console.log("loginotp action.payload: " + state.user);

            })
            .addCase(loginotp.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
    }
})

export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
    try {
        return await authService.register(user.email, user.parola, user.kullaniciAd)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})



export const logout = createAsyncThunk('auth/logout', async () => {
    await authService.logout()
})

export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
    try {
        return await authService.login(user.email, user.parola)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const loginotp = createAsyncThunk('auth/loginotp', async (user, thunkAPI) => {
    try {
        return await authService.loginotp(user)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})



export const { setUser } = authSlice.actions;
export const { reset } = authSlice.actions
export default authSlice.reducer