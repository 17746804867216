import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import "react-calendar/dist/Calendar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Dropdown, Modal, Row, Col, Card, Tab, Nav } from "react-bootstrap";
import Spinner from '../../components/Spinner/Spinner';
import { getEvent, getSubEvents, getEventAgendas, getEventAnnouncements, getEventUsers, joinEvent, getEventNotes, createEventNote, reset } from '../../features/event/eventSlice';
import EventGallery from '../../jsx/components/Ventic/Event/EventGallery';



function EventDetail() {
    let { eventId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeToggle, setActiveToggle] = useState("about");
    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const myUserId = useSelector((state) => state.auth.user.uid);


    const { event, events, eventAnnouncements, eventUsers, eventNotes, eventAgendas, isLoading, isError, message } = useSelector((state) => state.event);

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (!isAuthenticated) { navigate('/login') }
        if (isError) { console.log(message); }
        dispatch(getEvent(eventId))
        dispatch(getSubEvents(eventId))
        dispatch(getEventUsers(eventId))
        dispatch(getEventNotes(eventId))
        dispatch(getEventAgendas(eventId))
        dispatch(getEventAnnouncements(eventId))

        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 30000);

        // return () => { if (isAuthenticated) { dispatch(reset()) } }
        //}, [isAuthenticated, navigate, isError, message, dispatch])
    }, [isAuthenticated, navigate, seconds])







    const [noteTitle, setNoteTitle] = useState('')
    const [noteContext, setNoteContext] = useState('')




    const eventNoteData = {
        title: noteTitle,
        context: noteContext,
        files: [],
        meetingId: eventId,
        state: true,
        status: true,
        type: "1",
        noteId: "",
    };

    const onEventJoin = e => {
        //e.preventDefault();
        dispatch(joinEvent(eventId))
        setSeconds(seconds => seconds + 1);
    }

    const onSubmitNote = e => {
        e.preventDefault();

        if (noteTitle !== '' || noteContext !== '') {
            dispatch(createEventNote(eventNoteData));
            setNoteTitle('');
            setNoteContext('');
            console.log(eventNoteData);
        }

    }

    //if (isLoading) { return (<>Yükleniyor</>) }

    let startDateGet = new Date("2990-01-01")
    let endDateGet = new Date("1990-01-01")

    if (event.startDate) {

        console.log(event.startDate.seconds);

        const startDateGett = event.startDate;
        const startDateGetSec = startDateGett.seconds;

        const endDateGett = event.endDate;
        const endDateGetSec = endDateGett.seconds;

        console.log(new Date(startDateGetSec * 1000));
        console.log(new Date(endDateGetSec * 1000));

        startDateGet = new Date(startDateGetSec * 1000);
        endDateGet = new Date(endDateGetSec * 1000);

    }


    return (
        <Fragment>

            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded" style={{ backgroundImage: `url(${event.coverPhoto})` }}></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={event.image}
                                        className="rounded-circle cover_event_logo"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">{event.title}</h4>
                                        <p>
                                            Katılımcı Sayısı:  {event.users?.length}
                                            <br />
                                            Tarih Saat:
                                            {format(startDateGet, "dd") == format(endDateGet, "dd") ? (
                                                <>
                                                    {format(startDateGet, " dd MMM yyyy HH:mm")}
                                                    {format(endDateGet, " - HH:mm")}
                                                </>
                                            ) : (
                                                <>
                                                    {format(startDateGet, " dd - ")}
                                                    {format(endDateGet, "dd MMM yyyy")}
                                                    {format(startDateGet, " HH:mm")}
                                                    {format(endDateGet, " - HH:mm")}
                                                </>
                                            )}
                                        </p>
                                    </div>


                                    <Dropdown className="dropdown ms-auto" onClick={() => setActiveToggle("about")}>
                                        <div className="ticket-icon44  ms-0 d-none d-sm-inline-block" onClick={() => setActiveToggle("about")}>
                                            <i className="fa fa-info fs-16" aria-hidden="true"></i>
                                        </div>
                                    </Dropdown>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="row">




                <div className="col-xl-4">
                    <div className="row">


                        {!event.users?.includes(myUserId) ? (

                            <>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div onClick={() => onEventJoin()} className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
                                                <div className="widget-stat card bg-info">
                                                    <div className="card-body">
                                                        <div className="media">
                                                            <span className="me-3">
                                                                <i class="fa fa-check"></i>
                                                            </span>
                                                            <div className="media-body text-white text-end">
                                                                <h4 className="text-white">Etkinliğe Katıl</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                        ) : (

                            <>

                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">

                                            {new Date(startDateGet.getTime() - 5 * 60000) <= new Date() && new Date(endDateGet.getTime() + 5 * 60000) >= new Date() ? (

                                                <>
                                                    <a href={event.url}>
                                                        <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
                                                            <div className="widget-stat card bg-danger">
                                                                <div className="card-body">
                                                                    <div className="media">
                                                                        <span className="me-3">
                                                                            <i class="fa fa-play"></i>
                                                                        </span>
                                                                        <div className="media-body text-white text-end">
                                                                            <h4 className="text-white">Etkinliği İzle</h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>

                                                    {event.adminUsers?.includes(myUserId) ? (
                                                        <a href={event.urlHost}>
                                                            <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
                                                                <div className="widget-stat card bg-warning">
                                                                    <div className="card-body">
                                                                        <div className="media">
                                                                            <span className="me-3">
                                                                                <i class="fa fa-play"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-end">
                                                                                <h4 className="text-white">Yönetici Olarak Katıl</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    ) : (<></>)}
                                                </>

                                            ) : (

                                                <>
                                                    {startDateGet >= new Date() && endDateGet >= new Date() ? (

                                                        <>
                                                            <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
                                                                <div className="widget-stat card bg-success">
                                                                    <div className="card-body">
                                                                        <div className="media">
                                                                            <span className="me-3">
                                                                                <i class="fa fa-check"></i>
                                                                            </span>
                                                                            <div className="media-body text-white text-end">
                                                                                <h5 className="text-white">Etkinliğe Katıldınız.<br />Video Konferans Bağlantısı Buradan Paylaşılacaktır.</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                    ) : (

                                                        <>

                                                            {endDateGet <= new Date() ? (

                                                                <div className="col-xl-12 col-xxl-12 col-lg-12 col-sm-12">
                                                                    <div className="widget-stat card bg-primary">
                                                                        <div className="card-body">
                                                                            <div className="media">
                                                                                <span className="me-3">
                                                                                    <i class="fa fa-check"></i>
                                                                                </span>
                                                                                <div className="media-body text-white text-end">
                                                                                    <h5 className="text-white">Etkinlik Tamamlandı</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ) : (

                                                                <></>

                                                            )}

                                                        </>

                                                    )}

                                                </>

                                            )}

                                        </div>
                                    </div>
                                </div>
                            </>

                        )}



                        <div className="col-lg-12">
                            <div className="card">

                                <div className="card-body">

                                    {/* 
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("announcement")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-bullhorn fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("announcement")}>Duyuru</h5>
                                        </div>
                                    </div>
                                    */}

                                    {/* 
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("program")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-calendar fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("program")}>Program</h5>
                                        </div>
                                    </div>
                                    */}



                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("about")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-info fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("about")}>Etkinlik Hakkında</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("announcement")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-bullhorn fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("announcement")}>Duyurular</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("agenda")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-calendar fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("agenda")}>Gündem</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("notes")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-edit fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("notes")}>Notlarım</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("participants")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-users fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("participants")}>Katılımcılar</h5>
                                        </div>
                                    </div>

                                    {/* 
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("mymeetings")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-handshake-o fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("mymeetings")}>Görüşmelerim</h5>
                                        </div>
                                    </div>
                                    
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("sponsors")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-building fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("sponsors")}>Sponsorlar</h5>
                                        </div>
                                    </div>
                                    
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("scores")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-star fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("scores")}>Puan Durumu</h5>
                                        </div>
                                    </div>
                                    
                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("surveys")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-check-square-o fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("surveys")}>Anketler</h5>
                                        </div>
                                    </div>

                                    <div className="media align-items-center border-bottom p-2" onClick={() => setActiveToggle("support")}>
                                        <div className="ticket-icon44  ms-0 me-3 d-none d-sm-inline-block">
                                            <i className="fa fa-headphones fs-16" aria-hidden="true"></i>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="mt-0 mb-0" onClick={() => setActiveToggle("support")}>Destek</h5>
                                        </div>
                                    </div>
                                    */}



                                </div>
                            </div>
                        </div>

                        {/* 
                        <div className="col-xl-12 col-sm-6">
                            <EventGallery />
                        </div>
                        */}

                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-tab">
                                <div className="custom-tab-1">

                                    <div className="tab-content">

                                        <div id="announcement" className={`tab-pane fade ${activeToggle === "announcement" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Duyurular</h4>
                                            <p className="mb-2">
                                                <PerfectScrollbar
                                                    id="DZ_W_TimeLine"
                                                    className="widget-timeline dz-scroll  ps ps--active-y" >
                                                    <ul className="timeline">
                                                        {eventAnnouncements.map((list, index) => (
                                                            <li>
                                                                <div className="timeline-badge info"></div>
                                                                <Link to="" className="timeline-panel text-muted">

                                                                    <span>{format(list.messageDate.toDate(), "dd MMM yyyy HH:mm")}</span>
                                                                    <h6 className="mb-0">{list.message}</h6>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </PerfectScrollbar>
                                            </p>
                                        </div>



                                        <div id="agenda" className={`tab-pane fade ${activeToggle === "agenda" ? "active show" : ""}`} >
                                            <h4 className="text-primary mb-4">Gündem</h4>
                                            {eventAgendas.map((list, index) => (
                                                <div className="media mb-5 align-items-center event-list">
                                                    <div className="p-3 text-center me-3 date-bx bgl-primary">
                                                        <h2 className="mb-0 text-secondary fs-16 font-w600">{format(list.startTime.toDate(), "HH:mm")}</h2>
                                                        <h5 className="mb-1 text-black fs-16 ">{format(list.endTime.toDate(), "HH:mm")}</h5>
                                                    </div>
                                                    <div className="media-body px-0">
                                                        <h6 className="mt-0 mb-0 fs-16 text-bold">{list.title}</h6>
                                                        <p className="mt-0 mb-3 fs-14 text-bold">{list.details}</p>
                                                        <ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
                                                            <li>{format(list.startTime.toDate(), "HH:mm")} - {format(list.endTime.toDate(), " HH:mm dd MMMM yyyy")}</li>
                                                        </ul>
                                                        <div className="progress mb-0" style={{ height: "4px", width: "100%" }}>
                                                            <div className="progress-bar bg-warning progress-animated" style={{ width: "100%", height: "8px;" }} role="progressbar">
                                                                <span className="sr-only">60% Complete</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div id="program" className={`tab-pane fade ${activeToggle === "program" ? "active show" : ""}`} >
                                            <h4 className="text-primary mb-4">Etkinlik Programı</h4>
                                            {events.map((list, index) => (
                                                <div className="media mb-5 align-items-center event-list">
                                                    <div className="p-3 text-center me-3 date-bx bgl-primary">
                                                        <h2 className="mb-0 text-secondary fs-16 font-w600">{format(list.startDate.toDate(), "HH:mm")}</h2>
                                                        <h5 className="mb-1 text-black fs-16 ">{format(list.endDate.toDate(), "HH:mm")}</h5>
                                                    </div>
                                                    <div className="media-body px-0">
                                                        <h6 className="mt-0 mb-3 fs-14"><Link to={"/event"} className="text-black">{list.title}</Link></h6>
                                                        <ul className="fs-14 list-inline mb-2 d-flex justify-content-between">
                                                            <li>{format(list.startDate.toDate(), "dd MMM yyyy HH:mm")}</li>
                                                            {list.userLimit > 0 ? (
                                                                <li>10/{list.userLimit} Koltuk</li>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </ul>
                                                        <div className="progress mb-0" style={{ height: "4px", width: "100%" }}>
                                                            <div className="progress-bar bg-warning progress-animated" style={{ width: "100%", height: "8px;" }} role="progressbar">
                                                                <span className="sr-only">60% Complete</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>



                                        <div id="notes" className={`tab-pane fade ${activeToggle === "notes" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Notlarım</h4>
                                            <p className="mb-2">
                                                <div className="post-input">
                                                    <form onSubmit={onSubmitNote}>
                                                        <input type="text" onChange={(e) => setNoteTitle(e.target.value)} className="form-control input-default " placeholder="Başlık" />
                                                        <textarea onChange={(e) => setNoteContext(e.target.value)} name="textarea" id="textarea" cols={30} rows={5} className="form-control bg-transparent" placeholder="Detay" />
                                                        <button className='btn btn-primary ms-1' type='submit'>Ekle</button>
                                                    </form>
                                                </div>
                                                <PerfectScrollbar

                                                    id="DZ_W_TimeLine"
                                                    className="widget-timeline dz-scroll  ps ps--active-y" >
                                                    <ul className="timeline">
                                                        {eventNotes.map((list, index) => (
                                                            <li>
                                                                <div className="timeline-badge dark"></div>
                                                                <Link to="" className="timeline-panel text-muted">
                                                                    <h6 className="mb-0">{list.title}</h6>
                                                                    <p>{list.context}</p>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </PerfectScrollbar>
                                            </p>
                                        </div>

                                        <div id="mymeetings" className={`tab-pane fade ${activeToggle === "mymeetings" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Görüşmelerim</h4>
                                            <p className="mb-2"></p>
                                        </div>

                                        <div id="participants" className={`tab-pane fade ${activeToggle === "participants" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Katılımcılar</h4>
                                            <p className="mb-2">
                                                {eventUsers.map((list, index) => (
                                                    <Link to={"/Profile/" + list.userId}>
                                                        <div className="media pb-3 border-bottom mb-3 align-items-center" key={index}>
                                                            <div className="media-image me-2">
                                                                {list.thumbnail == "" ? (
                                                                    <img src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="" />

                                                                ) : (
                                                                    <img src={list.thumbnail} alt="" />
                                                                )}
                                                            </div>
                                                            <div className="media-body">
                                                                <h6 className="fs-16 mb-0">{list.name + " " + list.surName}</h6>
                                                                <div className="d-flex">
                                                                    <span className="fs-14 me-auto text-secondary">
                                                                        {list.businessName + " " + list.job}
                                                                    </span>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </p>
                                        </div>

                                        <div id="sponsors" className={`tab-pane fade ${activeToggle === "sponsors" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Etkinliğe Katıl</h4>
                                            <p className="mb-2"></p>
                                        </div>
                                        {/*
                                        <div id="scores" className={`tab-pane fade ${activeToggle === "scores" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Puan Durumu</h4>
                                            <p className="mb-2"></p>
                                        </div>

                                        <div id="surveys" className={`tab-pane fade ${activeToggle === "surveys" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Anketler</h4>
                                            <p className="mb-2"></p>
                                        </div>

                                        <div id="support" className={`tab-pane fade ${activeToggle === "support" ? "active show" : ""}`}>
                                            <h4 className="text-primary mb-4">Destek</h4>
                                            <p className="mb-2"></p>
                                        </div>
                                        */}

                                        <div id="about" className={`tab-pane fade ${activeToggle === "about" ? "active show" : ""}`}>
                                            <h4 className="text-primary">{event.title}</h4>
                                            <p className="mb-2">
                                                {event.meetingDescription}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >

    )
}

export default EventDetail