

import { db } from "../../config/firebase";
import { collection, addDoc, getDoc, query, setDoc, where, getDocs, doc, deleteDoc, updateDoc, orderBy, Timestamp } from 'firebase/firestore'


const createUser = async (userId, userPhone) => {

    const userData = {
        userId: userId,
        phoneNumber: userPhone,
        walletId: "",
        name: "name",
        surName: "surName",
        userName: "",
        userEmail: "",
        businessName: "",
        about: "",
        businessPosition: "",
        job: "",
        city: "",
        location: "",
        businessManager: false,
        premium: false,
        state: true,
        userPicture: "",
        thumbnail: "",
        businessId: '',
        createDate: Timestamp.now(),
        lastLoginDate: Timestamp.now(),
        pricingDate: Timestamp.now(),
        meetings: [],
        connections: [],
        connectionsReq: [],
        follower: [],
        followUser: [],
        token: "",
        country: "",
        language: "",
        invitations: [],
        meetingTags: [],
        businessInvite: [],
        directMessage: [],
        interests: [],
        business: [],
        blackList: [],
        social: {
            facebook: '',
            instagram: '',
            linkedin: '',
            twitter: '',
            youtube: '',
        },
    };

    const customDocRef = doc(db, 'users', userId);
    await setDoc(customDocRef, userData);
    const docSnap = await getDoc(customDocRef)
    return { ...docSnap.data(), id: customDocRef.id };
}

const getUser = async (userId) => {
    const docRef = await doc(db, 'users', userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return null;
    }
}



const updateUser = async (userData, id) => {
    const docRef = await doc(db, 'users', id);
    await updateDoc(docRef, userData)
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id: docSnap.id };
}


const userService = {
    createUser,
    updateUser,
    getUser,
}

export default userService