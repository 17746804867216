
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import eventService from './eventService'

const initialState = {
    events: [],
    eventsPast: [],
    eventUsers: [],
    eventAnnouncements: [],
    event: [],
    eventNotes: [],
    eventAgendas: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.events.unshift(action.payload)
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getEvents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvents.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.events = action.payload
                state.isError = false
            })
            .addCase(getEvents.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getSubEvents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubEvents.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.events = action.payload
                state.isError = false
            })
            .addCase(getSubEvents.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(deleteEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.events = state.events.filter((event) => event.id !== action.payload)
                state.isError = false
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(updateEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.events.unshift(action.payload)
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getEvent.pending, (state) => {
                state.isLoading = true
                state.isSuccess = false
            })
            .addCase(getEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.event = action.payload
            })
            .addCase(getEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getEventUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEventUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.eventUsers = action.payload
                state.isError = false
            })
            .addCase(getEventUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getEventAgendas.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEventAgendas.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.eventAgendas = action.payload
                state.isError = false
            })
            .addCase(getEventAgendas.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(getEventNotes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEventNotes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.eventNotes = action.payload
                state.isError = false
            })
            .addCase(getEventNotes.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })

            .addCase(createEventNote.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createEventNote.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.eventNotes.unshift(action.payload)
            })
            .addCase(createEventNote.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })


            .addCase(joinEvent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(joinEvent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.eventNotes.unshift(action.payload)
            })
            .addCase(joinEvent.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })


            .addCase(getEventsPast.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEventsPast.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.eventsPast = action.payload
                state.isError = false
            })
            .addCase(getEventsPast.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })


            .addCase(getEventAnnouncements.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEventAnnouncements.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.eventAnnouncements = action.payload
                state.isError = false
            })
            .addCase(getEventAnnouncements.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.message = action.payload
                state.isError = true
            })






    }
})

export const createEvent = createAsyncThunk('event/createEvent', async (eventData, thunkAPI) => {
    try {
        const user = thunkAPI.getState().auth.user
        return await eventService.createEvent(eventData, user)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getEvents = createAsyncThunk('event/getEvents', async (_, thunkAPI) => {
    try {
        return await eventService.getEvents()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getEventsPast = createAsyncThunk('event/getEventsPast', async (_, thunkAPI) => {
    try {
        return await eventService.getEventsPast()
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSubEvents = createAsyncThunk('event/getSubEvents', async (eventId, thunkAPI) => {
    try {
        return await eventService.getSubEvents(eventId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getEvent = createAsyncThunk('event/getEvent', async (id, thunkAPI) => {
    try {
        return await eventService.getEvent(id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteEvent = createAsyncThunk('event/deleteEvent', async (id, thunkAPI) => {
    try {
        return await eventService.deleteEvent(id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateEvent = createAsyncThunk('event/updateEvent', async (eventData, id, thunkAPI) => {
    try {
        return await eventService.updateEvent(eventData, id)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getEventUsers = createAsyncThunk('event/getEventUsers', async (eventId, thunkAPI) => {
    try {
        return await eventService.getEventUsers(eventId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const getEventAgendas = createAsyncThunk('event/getEventAgendas', async (eventId, thunkAPI) => {
    try {
        return await eventService.getEventAgendas(eventId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})


export const getEventAnnouncements = createAsyncThunk('event/getEventAnnouncements', async (eventId, thunkAPI) => {
    try {
        return await eventService.getEventAnnouncements(eventId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})





export const getEventNotes = createAsyncThunk('event/getEventNotes', async (eventId, thunkAPI) => {
    try {
        return await eventService.getEventNotes(eventId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const createEventNote = createAsyncThunk('event/createEventNote', async (noteData, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await eventService.createEventNote(noteData, noteData.meetingId, userId)
    } catch (error) {
        const message = error.message;
        console.log("createEventNoteError: " + message);
        return thunkAPI.rejectWithValue(message);
    }
})

export const joinEvent = createAsyncThunk('event/etkinligekatil', async (eventId, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user.uid
        return await eventService.joinEvent(eventId, userId)
    } catch (error) {
        const message = error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const { reset } = eventSlice.actions
export default eventSlice.reducer
