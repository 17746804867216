import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import loonca_site_icon from "../../../images/lonca/loonca_site_icon.png";
import loncanetworklogo from "../../../images/lonca/loncanetworklogo.jpeg";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img src={loonca_site_icon} className="img-lonca-logo  mb-5 mt-5 w-30 " />
          </Fragment>
        ) : (
          <Fragment>
            <img src={loonca_site_icon} className="img-lonca-logo  mb-5 mt-5 w-30 " />
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
