

import { db } from "../../config/firebase";
import { collection, addDoc, getDoc, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore'



const getProfile = async (profileId) => {
    const docRef = await doc(db, 'users', profileId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return null;
    }
}

const getMyProfile = async (profileId) => {
    const docRef = await doc(db, 'users', profileId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id }
    } else {
        return null;
    }
}

const updateMyProfile = async (profileData, userId) => {
    const docRef = await doc(db, 'users', userId);
    await updateDoc(docRef, profileData)
    const docSnap = await getDoc(docRef)
    return { ...docSnap.data(), id: docSnap.id };

}

const profileService = {
    updateMyProfile,
    getProfile,
    getMyProfile,
}

export default profileService