import React, { useState } from "react";

import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../../../features/auth/authSlice';

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown } from "react-bootstrap";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";


const Header = ({ onNote }) => {
  const [country, setCountry] = useState('ENGLISH');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth)

  const isAuthenticated = useSelector((state) => state.auth.user !== null);

  const onLogout = () => {
    dispatch(logout())
    //dispatch(reset())
    navigate('/login')
  }

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">



              <Link as="li" onClick={() => navigate(-1)} className="nav-item me-3">
                <i className="fa fa-arrow-left me-3" aria-hidden="true" ></i>
              </Link>





            </div>


            <ul className="navbar-nav header-right main-notification">


              <Link as="li" to="/MyProfile" className="nav-item me-3">
                <i className="fa fa-user me-3" aria-hidden="true" ></i>
                <span className="ms-2">Hesabım</span>
              </Link>



              <Link as="li" className="nav-item me-3">
                <i className="fa fa-power-off me-3" aria-hidden="true" onClick={onLogout}></i>
                <span className="ms-2" onClick={onLogout}>Çıkış</span>
              </Link>


              {/* 
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer"
                  role="button" data-toggle="dropdown"
                >
                  <div className="header-info me-3">
                    <span className="fs-16 font-w600 ">Profil</span>
                  </div>
                  <i className="" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-0 dropdown-menu-end">

                  <Link to="/MyProfile" className="dropdown-item ai-icon">
                    <i className="fa fa-user me-3" aria-hidden="true" ></i>
                    <span className="ms-2">Profil</span>
                  </Link>

                  <Link className="dropdown-item ai-icon">
                    <i className="fa fa-power-off me-3" aria-hidden="true" onClick={onLogout}></i>
                    <span className="ms-2" onClick={onLogout}>Çıkış</span>
                  </Link>

                </Dropdown.Menu>
              </Dropdown>
              */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
