import { auth } from '../../config/firebase';
//import { createUserWithEmailAndPassword, updateProfile, signOut, signInWithEmailAndPassword } from 'firebase/auth'

import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword } from 'firebase/auth'


const register = async (email, parola, kullaniciAd) => {
    const userResponse = await createUserWithEmailAndPassword(auth, email, parola)
    /*
    await updateProfile({
        displayName: "displayName",
        photoURL: "displayName"
    })
    */
    if (userResponse.user) {
        localStorage.setItem('user', JSON.stringify(userResponse.user))
    }
    return userResponse.user
}

const logout = async () => {
    await signOut(auth);
    localStorage.removeItem('user');
}

const login = async (email, parola) => {
    const userResponse = await signInWithEmailAndPassword(auth, email, parola)
    if (userResponse.user) {
        localStorage.setItem('user', JSON.stringify(userResponse.user))
    }
    return userResponse.user
}

const loginotp = async (user) => {
    if (user) {
        localStorage.setItem('user', JSON.stringify(user))
    }
    return user
}

const authService = {
    register,
    logout,
    login,
    loginotp,
}

export default authService