import React, { Fragment, useState, useEffect } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import { format } from 'date-fns';

import Spinner from '../../components/Spinner/Spinner'
import { getEvent, reset } from '../../features/event/eventSlice'


//** Import Image */
import profile01 from "../../images/profile/1.jpg";
import profile02 from "../../images/profile/2.jpg";
import profile03 from "../../images/profile/3.jpg";
import profile04 from "../../images/profile/4.jpg";
import profile05 from "../../images/profile/5.jpg";
import profile06 from "../../images/profile/6.jpg";
import profile07 from "../../images/profile/7.jpg";
import profile08 from "../../images/profile/8.jpg";
import profile09 from "../../images/profile/9.jpg";
import profile from "../../images/profile/profile.png";
import PageTitle from "../../jsx/layouts/PageTitle";

function EventDetail() {

    const [activeToggle, setActiveToggle] = useState("posts");
    const [sendMessage, setSendMessage] = useState(false);
    const [postModal, setPostModal] = useState(false);
    const [cameraModal, setCameraModal] = useState(false);
    const [linkModal, setLinkModal] = useState(false);
    const [replayModal, setReplayModal] = useState(false);
    const options = {
        settings: {
            overlayColor: "#000000",
        },
    };

    let { eventId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const isAuthenticated = useSelector((state) => state.auth.user !== null);
    const { event, isLoading, isError, message } = useSelector((state) => state.event);




    useEffect(() => {

        /*
        if (!isAuthenticated) {
            navigate('/login')
        }
        */

        if (isError) {
            console.log(message);
        }


        dispatch(getEvent(eventId))

        return () => {
            if (isAuthenticated) {
                dispatch(reset())
            }

        }

    }, [isAuthenticated, navigate, isError, message, dispatch])


    if (isLoading) {
        return <Spinner />
    }



    return (
        <Fragment>

            <div className="row">
                <div className="col-lg-12">
                    <div className="profile card card-body px-3 pt-3 pb-0">
                        <div className="profile-head">
                            <div className="photo-content ">
                                <div className="cover-photo rounded" style={{ backgroundImage: `url(${event.coverPhoto})` }}></div>
                            </div>
                            <div className="profile-info">
                                <div className="profile-photo">
                                    <img
                                        src={event.image}
                                        className="rounded-circle cover_event_logo"
                                        alt="profile"
                                    />
                                </div>
                                <div className="profile-details">
                                    <div className="profile-name px-3 pt-2">
                                        <h4 className="text-primary mb-0">VİDEO {event.title}</h4>
                                        <p>Tarih Saat</p>
                                    </div>
                                    <Dropdown className="dropdown ms-auto">
                                        <Dropdown.Toggle
                                            variant="primary"
                                            className="btn btn-primary light sharp i-false"
                                            data-toggle="dropdown"
                                            aria-expanded="true"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width="18px"
                                                height="18px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    fill="none"
                                                    fillRule="evenodd"
                                                >
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                    <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                    <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                </g>
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                            <Dropdown.Item className="dropdown-item">
                                                <i className="fa fa-user-circle text-primary me-2" />
                                                View profile
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item">
                                                <i className="fa fa-users text-primary me-2" />
                                                Add to close friends
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item">
                                                <i className="fa fa-plus text-primary me-2" />
                                                Add to group
                                            </Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item">
                                                <i className="fa fa-ban text-primary me-2" />
                                                Block
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-statistics">
                                        <div className="text-center">
                                            <div className="row">
                                                <div className="col">
                                                    <h3 className="m-b-0">{event.users?.length}</h3><span>Katılımcı</span>
                                                </div>
                                                <div className="col">
                                                    <h3 className="m-b-0">{event.submeetings?.length}</h3> <span>Program</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => setSendMessage(true)}>Katıl</Button>
                                            </div>
                                        </div>
                                        {/* send Modal */}
                                        <Modal className="modal fade" show={sendMessage} onHide={setSendMessage}>
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Send Message</h5>
                                                    <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => setSendMessage(false)}>

                                                    </Button>
                                                </div>
                                                <div className="modal-body">
                                                    <form className="comment-form" onSubmit={(e) => { e.preventDefault(); setSendMessage(false); }}>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
                                                                    <input type="text" className="form-control" defaultValue="Author" name="Author" placeholder="Author" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor="email" className="text-black font-w600"> Email <span className="required">*</span></label>
                                                                    <input type="text" className="form-control" defaultValue="Email" placeholder="Email" name="Email" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-3">
                                                                    <label htmlFor="comment" className="text-black font-w600">Comment</label>
                                                                    <textarea rows={8} className="form-control" name="comment" placeholder="Comment" defaultValue={""} />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="form-group mb-3">
                                                                    <input type="submit" value="Post Comment" className="submit btn btn-primary" name="submit" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <h5 className="text-primary">Today Highlights</h5>
                                </div>
                                <div className="card-body pt-3"	>
                                    <div className="profile-blog ">
                                        <img src={profile01} alt="profile" className="img-fluid  mb-4 w-100 " />
                                        <Link to="/post-details"> <h4>Darwin Creative Agency Theme</h4> </Link>
                                        <p className="mb-0">
                                            A small river named Duden flows by their place and supplies
                                            it with the necessary regelialia. It is a paradisematic
                                            country, in which roasted parts of sentences fly into your
                                            mouth.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 pb-0">
                                    <h5 className="text-primary ">Interest</h5>
                                </div>
                                <div className="card-body pt-3">
                                    <div className="profile-interest ">
                                        <div className="row sp4">
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                <a href={profile02}> <img src={profile02} alt="profileImage" className="img-fluid" /> </a>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                <a href={profile03}> <img src={profile03} alt="profile" className="img-fluid" /></a>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                <a href={profile04}><img src={profile04} alt="profile" className="img-fluid" /> </a>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                {" "}
                                                <a href={profile02}><img src={profile02} alt="profile" className="img-fluid" /> </a>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                <a href={profile03} className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col" >
                                                    <img src={profile03} alt="profile" className="img-fluid" />
                                                </a>
                                            </div>
                                            <div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
                                                <a href={profile04} className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col">
                                                    <img src={profile04} alt="profile" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="profile-tab">
                                <div className="custom-tab-1">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item" onClick={() => setActiveToggle("posts")}>
                                            <Link to="#my-posts" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}>Program</Link>
                                        </li>
                                        <li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
                                            <Link to="#about-me" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}>Hakkında</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}>Setting</Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="my-posts" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
                                            <div className="my-post-content pt-3">

                                                {/*
                                                    <Card>
                                                        <Card.Header>
                                                            <Card.Title>Nav Pills Tabs</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                                                <Nav as="ul" className="nav-pills mb-4 justify-content-end">
                                                                    {tabData.map(
                                                                        (data, i) =>
                                                                            i !== tabData.length - 1 && (
                                                                                <Nav.Item as="li" key={i}>
                                                                                    <Nav.Link eventKey={data.name.toLowerCase()}>
                                                                                        Tab {i === 1 ? "Two" : i === 2 ? "Three" : "One"}
                                                                                    </Nav.Link>
                                                                                </Nav.Item>
                                                                            )
                                                                    )}
                                                                </Nav>
                                                                <Tab.Content className="">
                                                                    {tabData.map(
                                                                        (data, i) =>
                                                                            i !== tabData.length - 1 && (
                                                                                <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                                                                    <p>{data.content}</p>
                                                                                    <p>{data.content}</p>
                                                                                </Tab.Pane>
                                                                            )
                                                                    )}
                                                                </Tab.Content>
                                                            </Tab.Container>
                                                        </Card.Body>
                                                    </Card>
                                                */}

                                            </div>
                                        </div>
                                        <div id="about-me" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
                                            <div className="profile-about-me">
                                                <div className="pt-4 border-bottom-1 pb-3">
                                                    <h4 className="text-primary">{event.title}</h4>
                                                    <p className="mb-2">
                                                        {event.meetingDescription}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="profile-skills mb-5">
                                                <h4 className="text-primary mb-2">Skills</h4>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1"> Admin</Link>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1" > Dashboard</Link>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Photoshop</Link>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Bootstrap</Link>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Responsive</Link>
                                                <Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Crypto</Link>
                                            </div>
                                            <div className="profile-lang  mb-5">
                                                <h4 className="text-primary mb-2">Language</h4>
                                                <Link to="/app-profile" className="text-muted pe-3 f-s-16">
                                                    <i className="flag-icon flag-icon-us" />English
                                                </Link>
                                                <Link to="/app-profile" className="text-muted pe-3 f-s-16">
                                                    <i className="flag-icon flag-icon-fr" />French
                                                </Link>
                                                <Link to="/app-profile" className="text-muted pe-3 f-s-16">
                                                    <i className="flag-icon flag-icon-bd" />Bangla
                                                </Link>
                                            </div>
                                            <div className="profile-personal-info">
                                                <h4 className="text-primary mb-4">
                                                    Personal Information
                                                </h4>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>Mitchell C.Shay</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>example@examplel.com</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500">  Availability<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>Full Time (Free Lancer)</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500">Age<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>27</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500">  Location<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>Rosemont Avenue Melbourne, Florida</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-3">
                                                        <h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
                                                    </div>
                                                    <div className="col-9">
                                                        <span>07 Year Experiences</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
                                            <div className="pt-3">
                                                <div className="settings-form">
                                                    <h4 className="text-primary">Account Setting</h4>
                                                    <form onSubmit={(e) => e.preventDefault()}>
                                                        <div className="row">
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label className="form-label" >Email</label>
                                                                <input type="email" placeholder="Email" className="form-control" />
                                                            </div>
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label className="form-label">Password</label>
                                                                <input type="password" placeholder="Password" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Address</label>
                                                            <input type="text" placeholder="1234 Main St" className="form-control" />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Address 2</label>
                                                            <input type="text" placeholder="Apartment, studio, or floor" className="form-control" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group mb-3 col-md-6">
                                                                <label className="form-label" >City</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                            <div className="form-group mb-3 col-md-4">
                                                                <label className="form-label">State</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="inputState"
                                                                    defaultValue="option-1"
                                                                >
                                                                    <option value="option-1">Choose...</option>
                                                                    <option value="option-2">Option 1</option>
                                                                    <option value="option-3">Option 2</option>
                                                                    <option value="option-4">Option 3</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group mb-3 col-md-2">
                                                                <label className="form-label">Zip</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <div className="form-check custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="gridCheck"
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="gridCheck"
                                                                >
                                                                    Check me out
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <button className="btn btn-primary" type="submit">Sign in</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default EventDetail